// extracted by mini-css-extract-plugin
export var qaAchievementsSection = "y_dz";
export var qaBenefitsSection = "y_dp";
export var qaBusinessSection = "y_dq";
export var qaCtaSection = "y_dw";
export var qaCtaSection__cta = "y_dx";
export var qaDomainExpertiseSection = "y_dt";
export var qaResultSection = "y_ds";
export var qaServicesSection = "y_dl";
export var qaTechStackSection = "y_dv";
export var qaTestimonialPost = "y_dr";
export var qaTestingServicesSection = "y_dm";
export var qaVideoClientSection = "y_dB";
export var qaWhenDoYouNeedSection = "y_dn";
export var qaWhyChooseSection = "y_dy";